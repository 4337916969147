body {
    font-family: 'Lato', sans-serif;
}
.mainContainer {
    padding-left: 50px;
    padding-right: 50px;
    position: relative;
    z-index: 1;
}

.headerWrapper {
    padding-bottom: 45px;
    padding-top: 45px;
}
.btn {
    min-width: 130px;
    min-height: 45px;
    border-radius: 5px;
    font-size: 18px;
    font-family: inherit;
    font-weight: 700;
    color: #fff;
}

.btn:hover, 
.btn:focus, 
.btn:active {
    opacity: .7;
}

.register {
    background-color: #d5a1ff;
}

.btn:hover, .btn:focus, .btn:active {
    color: #fff;
}

.btnRightCol {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.blueOverLay {
    height: 100%;
    width: 38%;
    position: fixed;
    background: #3e2465;
    bottom: 0;
    top: 0;
}

.aboutMedialHealthWrapper .title {
    font-size: 46px;
    color: #ffffff;
    font-family: inherit;
    font-weight: 400;
    margin-bottom: 15px;
}

.aboutMedialHealthWrapper .landing-subtitle {
    color: #ffffff;
    font-family: inherit;
}

.aboutMedialHealthWrapper .title span {
    display: block;
    font-size: 26px;
}

.aboutMedialHealthWrapper p {
    color: #ded3ef;
    font-size: 20px;
    font-weight: 300;
}

.downloadNowWrapper {
    margin-top: 20px;
    margin-left: -7px;
    margin-right: -7px;
    margin-bottom: 15px;
}

.downloadNowWrapper a {
    display: inline-block;
    padding-left: 7px;
    padding-right: 7px;
    margin-top: 15px;
    margin-bottom: 15px;
}

.aboutMedialHealthWrapper {
    padding: 0;
}

.aboutMedialHealthCol {
    max-width: 27.5%;
    flex: 0 0 27.5%;
}

.carouselCol {
    max-width: 72.5%;
    flex: 0 0 72.5%;
}

.aboutMedialHealthCol, .carouselCol {
    padding-top: 0px;
}

.landingMainWrapper {
    background: #f9f6ff;
}

.image-carousel {
    border: 3px solid;
    border-color: #ded3ef;
}

.sign-up-link {
    text-decoration: underline;
    color: #3e2465;
}

.sign-up-link:hover{
    text-decoration: underline;
    color: #ded3ef;
}

.site-page {
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
    font-family: inherit;
}

.test-user-signup, .site-page {
    margin-left: auto;
    margin-right: auto;
}

.error-message {
    color: red;
}

/*Owl Carousel Customize*/
.carouselCol .owl-nav .owl-prev, .carouselCol .owl-nav .owl-next {
    width: 66px;
    border-radius: 100%;
    background-color: #efe6f9;
    font-size: 38px;
    color: #8d7ea3;
    padding: 0;
    line-height: 66px;
    text-align: center;
    margin: 7px;
    transition: all .25s ease;
}

.carouselCol .owl-nav .owl-prev:hover, .carouselCol .owl-nav .owl-prev:focus,.carouselCol .owl-nav .owl-prev:active, .carouselCol .owl-nav .owl-next:hover, .carouselCol .owl-nav .owl-next:focus, .carouselCol .owl-nav .owl-next:active {
    background: #3e2465;
}

.carouselCol .owl-nav {
    justify-content: flex-end;
    display: flex;
    flex-direction: row;
    margin-top: 33px;
}

.downloadNowWrapper a img {
    height: 50px;
}  

.app-nav {
    background: #f9f6ff;
}

.nav-logo {
    height: 20px;
    padding-right: 10px;
}

/* Media Query */

@media (max-width: 1400px) {
    .aboutMedialHealthWrapper {
        padding: 30px 0 30px 0;
    }    
    .mainContainer {
        padding-left: 30px;
        padding-right: 30px;
    }    
    .downloadNowWrapper a img {
        width: 150px;
    }    
    .carouselCol .owl-nav .owl-prev, .carouselCol .owl-nav .owl-next {
        width: 50px;
        height: 50px;
        line-height: 50px;
        font-size: 28px;
    }
    .aboutMedialHealthCol {
        max-width: 28.5%;
        flex: 0 0 28.5%;
    }  
    .carouselCol {
        max-width: 71.5%;
        flex: 0 0 71.5%;
    }
    .sign-up-link {
        text-decoration: underline;
        color: #3e2465;
    }

    .sign-up-link:hover{
        text-decoration: underline;
        color: #ded3ef;
    }   
}


@media (max-width: 1259px) {
    .aboutMedialHealthCol {
        max-width: 33.33%;
        flex: 0 0 33.33%;
    }  
    .carouselCol {
        max-width: 66.67%;
        flex: 0 0 66.67%;
    } 
    .blueOverLay {
        width: 42%;
    }   
    .downloadNowWrapper a img {
        width: 150px;
    }
    .sign-up-link{
        text-decoration: underline;
        color: #3e2465;
    }
    .sign-up-link:hover{
        text-decoration: underline;
        color: #ded3ef;
    }        
}

@media (max-width: 990px) {
    .blueOverLay {
        width: 50%;
    }  
    .aboutMedialHealthWrapper .title {
        font-size: 32px;
    }
    .aboutMedialHealthWrapper .title span {
        font-size: 22px;
    }
    .aboutMedialHealthWrapper p {
        font-size: 16px;
    }
    .downloadNowWrapper a img {
        max-width: 125px;
    } 
    .mainContentWrapper .row {
        flex-flow: column-reverse;
    }
    .aboutMedialHealthCol, .carouselCol {
        max-width: 100%;
        flex: 0 0 100%;
    }  
    .blueOverLay {
        width: 100%;
        border-radius: 0;
    } 
    .carouselCol .owl-nav .owl-prev:hover, .carouselCol .owl-nav .owl-prev:focus, .carouselCol .owl-nav .owl-prev:active, .carouselCol .owl-nav .owl-next:hover, .carouselCol .owl-nav .owl-next:focus, .carouselCol .owl-nav .owl-next:active {
        background: violet;
    } 
 
    .register:hover, .register:focus, .register:active {
        color: #333;
    }
    .aboutMedialHealthCol, .carouselCol {padding-top: 0;}     
    
    .sign-up-link{
        text-decoration: underline;
        color: #ffffff;
    }
    .sign-up-link:hover{
        text-decoration: underline;
        color: #ded3ef;
    }  
}

@media (max-width: 767px) {  
    .brandLogo img {
        max-width: 180px;
    }
    .mainContainer {
        padding-left: 15px;
        padding-right: 15px;
    } 
    .btn {
        font-size: 16px;
        min-height: 40px;
        min-width: 0;
    }           
    .headerWrapper {
        padding-bottom: 0;
    }
    .downloadNowWrapper {
        text-align: center;
    } 

    .downloadNowWrapper a img {
        height: 45px;
    }    
    .sign-up-link{
        text-decoration: underline;
        color: #ffffff;
    }
    .sign-up-link:hover{
        text-decoration: underline;
        color: #ded3ef;
    }  

}

.sortable-handler {
    touch-action: none;
  }